import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Relationship</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Relationship</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
             <p className="playfair text-black text-xl md:text-3xl">Gemini Relationship</p><br/>
             Gemini is the best to start a relationship. Gemini relationships are challenging because they are happiest when they are free and independent, and the idea of attachment tends to scare Gemini a little. <br/>
             <br></br>
             Best done with a partner who is giving. You seek relationships that understand and nurture your diverse interests and lofty ambitions. You are loyal, passionate, and exciting to be with, and you bring amazing energy to a relationship. You sacrifice a lot for someone you think deserves your love. You support your loved ones from the bottom of your hearts so that they can grow and live fulfilling lives.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Gemini as lovers</p><br/>
             You are very attractive when in love and bring a lot of fun and excitement to your partner. You are unable to bond with your partner quickly. Once you've found the right person who respects your freedom and thoughts, you'll be ready to bond and come to terms with that person. Even after committing, it can be quite disbelieving. You are enthusiastic, fun-loving, flirtatious, curious, quick-witted, and resourceful. You are extremely loyal and share many new experiences, variety, and lots of fun with your partner. <br/>
             <br></br>
             You can seem cold and distant to them at times. It's because of your deep thinking and habit of thinking deeply before you act in any situation. You often display contradictory sides of yourself in relationships, confusing others and not convincing them of your true feelings.<br/> 
             You are loving and caring, but you hide your strong feelings until they know you. Even if you love your freedom, you may still be emotionally dependent on your partner. You are enthusiastic and love to get excited, but at the same time, you need warmth and consideration from your partner. <br/> 
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini as a colleague</p><br/>
            tasks. You love communicating and interacting with colleagues and peers. You are a conversationalist, which is a great asset to you at work. You have a lot of tireless energy but are impatient most of the time. You are proficient at negotiating and are notoriously flexible. You can adapt to any situation and think quickly. <br/>
            <br></br>
            They tend to get bored quickly, so they jump on to new tasks and projects without finishing the task at hand. You are born with a lightning-quick wit and an innate charm. You get bored easily, so it's hard to keep doing the same job for a long time. Staying satisfied and happy requires a tremendous amount of stimulation, change, and new challenges.<br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini as friends</p><br/>
            You are fun-loving, willing to experiment, and always love to meet new people. Your friends will love hanging out with you. You can make your friends go crazy so they have an exciting time and lots of fun with you. You can spend special time with friends who are very close and special to you. Never hesitate to point them in the right direction. You are a great storyteller and always have something to tell your friends. <br/>
            <br></br>
            You run with different emotions and are always full of energy. You are always ready to annoy your friends and make a comeback very quickly. You pride yourself on your cleverness and intelligence. You rarely hurt my friends, but you do apologize profusely for my mistakes. Your friends rarely come across your tough exterior, and their fear of it shows in humor or self-deprecating comments. You need your friends to be with you all the time. They are interested in learning new things and new information. <br/>
            <br></br>
            Also, Read Gemini Friendly and Worst Enemies. <br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini as  Boss</p><br/>
            You are smart, impulsive, and innovative, but you are also inspirational. Your employees won't have a boring day or feel bored. Your office is always filled with excitement and fast-paced activities and tasks. You have a great ability to bring out the best in your subordinates. Grow by sharing feedback,  thoughts, ideas, and information and verbally collaborating with teammates and colleagues. You dislike rigid attitudes and traditional ways of thinking, which make you an effective and inspiring leader.<br/>
            <br></br>
            Constantly changing company policies, office furniture, and other things. We never fix an employee's routine. But your moods are constantly changing, making it difficult for employees to understand and keep up with your mood swings. You receive a bunch of innovative and exciting ideas, but you're nervous about implementing them.  <br/>
            <br></br>
            To understand the ins and outs of your relationship with your boss: Get a fully personalized report and a handwritten compatibility report for you and your boss.<br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini's father</p><br/>
            You are a carefree, happy, fun-loving, easy-going, and playful dad. You influence those around you. This trait helps build strong bonds with children. They are very dear to you and share everything with you. You love to give your children freedom and never set rules or boundaries that they must follow. Your children will always appreciate the freedom and space you give them. <br/>
            <br></br>
            Your greatest goal in life is to keep your children happy and healthy. You are kind, patient, and trusting with them. You are the one who makes your child and family smile. They will never be upset or stressed in your presence. But sometimes children learn that they can get through anything if their parents are comfortable with them. You may start playing with your emotions and compassion. <br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini as Mother</p><br/>
            You are very smart, intelligent, and attractive to look at. We are always planning something to keep the kids busy while having fun. You have many creative ideas for playing and spending time with your children. You feel happy and are rarely bored with your presence. You always make sure they have you or someone to confide in when you are away. You are a fun, hipster, who loves to play with children and act like their best friend. Among you is a child who brightens the mood of children with random jokes and lively personalities. <br/>
            <br></br>
            Enough energy for the whole day and even when the kids go to bed. This is when you do household chores, spend time with your spouse, or when are alone reading a book or watching something that interests you. You're not a perfect mother, but experience teaches you. Your roles and responsibilities. You maximize your vitality and master the act of balancing. <br/>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Also Read:</p>Gemini Woman by Zodiac Sign<br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini as Child</p><br/>
            Gemini is talkative, charming, and sociable. Calm yet quick-witted, humorous, curious, with a strong intellect and a rich imagination. They are fully aware of what is happening in the world and think a lot. They are bright and intelligent and always have a smile on their eyes and faces. They make their classmates laugh with their funny gigs and jokes. Twins can start talking earlier than other children of their age. You are constantly engaged in activities that stimulate your mind and increase your intelligence. Jump from one activity to the next in an instant. And moods and choices can change in the blink of an eye. <br/>
            <br></br>
            These children are malleable, indecisive, and easily distracted. Out of curiosity, you eagerly gather a lot of information while traveling and sharing it with others. They love sharing their knowledge and information with others. Gemini children, like adults, have only a superficial knowledge of many subjects because they are prone to change, seek variety, and are interested in different subjects. They rarely have detailed or detailed knowledge of any subject. These children have the appearance of curious teenage toddlers. <br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Gemini  Husband</p><br/>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Best  for Marriage:</p> Aries, Leo, Libra, and Aquarius Women <br/>
            <br></br>
            You are humorous, unpredictable, mysterious, and often loyal to your spouse. Hypnotize her with your romantic words. Your split personality reflects her two opposing behaviors within you. You need a partner who excites your spirit and knows how to enjoy and enjoy life. They often get married in their later years after wandering around many places and gaining experience. <br/>
            <br></br>
            You have a strong need for freedom and rarely offer commitment or togetherness to your partner. You are at the forefront of intellectual exchange, sharing ideas, taking vacations, meeting new people, and learning new things with your spouse. You keep your spouse busy. You are restless, overexcited, energetic, and unpredictable. <br/>
            <br></br>
            Do not let your wife rest before you. You are not a home person, you dislike monotonous routines and have little desire to do your daily tasks. You are very active in your social life and want to see your friends regularly to find out about their well-being and happiness. They tend to go out, both literally and figuratively. You rarely take anything seriously in your life. <br/>
            <br></br>
            Check out our 2024 horoscope for more information.<br/>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">GEMINI as Wife</p><br/>
            <br></br>
            <p className="font-bold text-orange-500 inline-block mr-2">Best for Marriage:</p>Aries, Leo, Libra, and Aquarius men.<br/>
            <br></br>
            You are knowledgeable, smart, articulate, the ideal wife, and occupy a strong and powerful position in your career. increase. You never neglect to work for your career and your family. You won't give up on my career until you realize it's impossible to be successful in both fields. But most of the time we can agree on both. You want a strong intellectual bond with your partner. <br/>
            <br></br>
            They don't like spending a lot of time indoors. Your moods can change in an instant, but your emotional feelings, gestures, and charismatic personality will keep your marriage alive. You are alert, conversational, and methodical by nature, and rarely stand amidst chaos and dirt. With your charming personality, you make everything shine. You are charitable in spending money and the one thing you always remember is that money will never be an issue in your marriage as you are extravagant.<br/>
            <br></br>
           </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
